import Storage from "@/util/Storage";
import {addBarList, updateBarList} from "@/Api/ShopAdmin";
import {stateArr} from "@/views/SocialCircle/AddProductBar/Model";
import {Toast} from "vant";
let userInfo:any = {}
// 添加商品导航栏
export function addProductBar(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let num = 0;
    stateArr.forEach(item=>{
        if ( body[item] ) num ++;
    })
    if (stateArr.length < num) {
        Toast("请输入内容");
        return Promise.reject();
    }
    userInfo = Storage.GetData_!("UserData")
    try{
        try{
            userInfo = JSON.parse(userInfo as string)
        }catch (e) {
            userInfo = eval(userInfo as string)
        }
    }catch (e) {
        console.info("转换失败")
    }

    body.schoolCode = userInfo.school
    Toast.loading({ duration:0,message:"添加中~~~" })
    return Promise.resolve(addBarList({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 修改商品导航栏
export function updateProductBar(body:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let num = 0;
    stateArr.forEach(item=>{
        if ( body[item] != oldData[item] ){
            num ++;
        }
    })
    if ( num < 1 ){
        Toast("请修改内容");
        return Promise.resolve()
    }
    userInfo = Storage.GetData_!("UserData")
    try{
        try{
            userInfo = JSON.parse(userInfo as string)
        }catch (e) {
            userInfo = eval(userInfo as string)
        }
    }catch (e) {
        console.info("转换失败")
    }

    body.schoolCode = userInfo.school
    body.type = oldData.type || 0
    body.id = oldData.id
    Toast.loading({ duration:0,message:"修改中~~~" })
    return Promise.resolve(updateBarList({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
